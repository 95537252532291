<template>
  <div class="transaction-memo childpool-wrap">
    <CCard>
      <CCardBody>
        <CRow class="p-1">
          <CCol col="12" xl="6" class="p-1 pl-3 pr-3">
            <CInput label="Partner Id:" horizontal :value="partnerID" disabled />
          </CCol>
          <CCol col="12" xl="6" class="p-1 pl-3 pr-3">
            <CInput label="Partner Name:" horizontal :value="partnerName" disabled />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardBody>
        <div class="font-weight-bold mb-1">Transaction Memo</div>
        <ValidationObserver ref="form">
          <CForm @submit.prevent="onSubmit">
            <CRow>
              <CCol col="12" sm="7" xl="6">
                <CRow
                  class="custom-input"
                  alignVertical="center"
                  v-for="memo in memos"
                  :key="memo.id"
                >
                  <CCol class="text-uppercase" sm="3">{{memo.platform}}</CCol>
                  <CCol sm="9">
                    <ValidationProvider name rules="required" v-slot="{ errors }">
                      <input class="input-field" v-model="memo.memo" :name="memo.platform" />
                      <p class="err-msg">{{errors[0]}}</p>
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>
              <CCol col="12" class="mt-2 text-right">
                <CButton type="submit" class="pl-4 pr-4" color="primary">Save</CButton>
              </CCol>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardBody>
        <div class="font-weight-bold m-1">History</div>
        <CDataTable
          class="mb-0"
          outlined
          striped
          hover
          :items="transactions"
          :fields="tableHeaders"
          head-color="light"
          no-sorting
        >
          <td class="text-center" slot="order" slot-scope="{ item, index }">{{ index + 1}}</td>
          <td class="text-center" slot="platform" slot-scope="{ item }">{{ item.platform }}</td>
          <td class="text-left" slot="memo" slot-scope="{ item }">{{ item.memo }}</td>
          <td
            class="text-center"
            slot="updateAt"
            slot-scope="{ item }"
          >{{ item.updated_at | moment("YYYY-MM-DD HH:mm") }}</td>
          <td
            class="text-left"
            slot="updated_by_user_name"
            slot-scope="{ item }"
          >{{ item.updated_by_user_name || '' }}</td>
        </CDataTable>

        <div>
          <CPagination
            v-if="pages>1"
            :activePage.sync="activePage"
            :pages="pages"
            align="end"
            @update:activePage="pushPage"
          />
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import _ from 'lodash';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';

export default {
  name: 'TransactionMemo',
  data() {
    return {
      tableHeaders: [
        { key: 'order', label: '#', _classes: 'text-center' },
        { key: 'platform', label: 'Platform', _classes: 'text-center' },
        { key: 'memo', label: 'Memo', _classes: 'text-left' },
        { key: 'updateAt', label: 'Updated At', _classes: 'text-center' },
        { key: 'updated_by_user_name', label: 'Updated By', _classes: 'text-left' },
      ],
      transactions: [],
      limit: 5,
      pages: 0,
      activePage: 1,
      partner: {},
      partnerID: '',
      partnerName: '',
      memos: [],
    };
  },
  computed: {
    offset() {
      return (this.activePage - 1) * this.limit;
    },
  },
  async mounted() {
    this.partnerID = this.$route.params.id;
    this.partnerName = await this.getCurrentPatner();

    const [memo, transactions] = await Promise.all([this.getMemo(), this.getMemoHistory()]);
    if (!memo || !transactions) {
      return;
    }

    this.memos = memo.items.slice();
    this.transactions = transactions.items.slice();

    this.pages = Math.ceil(transactions.total / this.limit);
  },
  methods: {
    async getMemo() {
      try {
        const result = await this.$http.get(endpoints.getMemo(this.partnerID));
        return result.data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    async getCurrentPatner() {
      try {
        const resultP = await this.$http.get(endpoints.getGrandchild(this.partnerID));
        return resultP.data.name;
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    async getMemoHistory() {
      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
        };
        const history = await this.$http.get(endpoints.getMemoHistory(this.partnerID), {
          params: params,
        });
        return history.data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    async onSubmit() {
      this.$refs.form.validate().then(isValid => {
        if (!isValid) {
          return;
        }

        this.save();
      });
    },

    async save() {
      try {
        const items = this.memos.map(el => {
          return {
            platform: el.platform,
            memo: el.memo,
          };
        });
        const result = await this.$http.post(endpoints.updateMemo(this.partnerID), { items: items });

        this.$notify({
          group: 'notifications',
          type: 'success',
          title: 'Success',
          text: 'Your changes have saved',
          duration: 1000,
        });
      } catch (err) {
        console.log(err);
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
          duration: 1000,
        });
      }
    },

    async pushPage() {
      const transactions = await this.getMemoHistory();
      if (!transactions) {
        return;
      }
      this.transactions = transactions.items.slice();
    },
  },
};
</script>
<style lang="scss" scoped>
.transaction-memo /deep/ {
  .form-control {
    text-align: left;
  }
  .form-group {
    margin-bottom: 0;
  }

  .custom-input {
    padding-top: 4px;
    .input-field {
      padding: 6px 12px;
      width: 100%;
      border: 1px solid #d8dbe0;
      border-radius: 0.25rem;
      text-align: left;
    }
  }
  /*  Phat css*/
  .text-left{
    text-align: left;
  }
  .card {
    .card-body {
      .col-form-label {
        text-align: right;
        padding-right: 15px;
        line-height: 25px;
        color: #3e4b65;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
      }
      .text-uppercase {
        text-align: right;
        padding-right: 15px;
        line-height: 25px;
        color: #3e4b65;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
      }
      .btn-primary {
        min-width: 146px;
        height: 36px;
        border-radius: 4px;
        background-color: #3b4b7e;
        color: #ffffff;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
        border: none;
        outline: none !important;
        -webkit-box-shadow: none;
        box-shadow: none;
        text-align: center;
      }
    }
  }
}
.err-msg {
  font-size: 14px;
  font-weight: 300;
  line-height: 28px;
  color: #ff0000;
  margin-bottom: 10px;
}
</style>
